export const Footer = () => {
  return (
    <div className="footer-container">
      <div className="-mt-2">© 2024 Katya Lavrova</div>
      <div className="flex space-x-2">
        <a
          className="telegram-link"
          target="_blank"
          href="https://t.me/lavrushhaa"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="46"
            viewBox="0 0 72 72"
          >
            <path
              fill="#ffffff"
              d="M 36 12 C 22.745 12 12 22.745 12 36 C 12 49.255 22.745 60 36 60 C 49.255 60 60 49.255 60 36 C 60 22.745 49.255 12 36 12 z M 36 20 C 44.837 20 52 27.163 52 36 C 52 44.837 44.837 52 36 52 C 27.163 52 20 44.837 20 36 C 20 27.163 27.163 20 36 20 z M 44.132812 28.210938 C 43.676063 28.158297 43.102187 28.282672 42.460938 28.513672 C 41.288937 28.935672 26.308359 35.296062 25.443359 35.664062 C 24.623359 36.013063 23.847656 36.393312 23.847656 36.945312 C 23.847656 37.332313 24.077938 37.549391 24.710938 37.775391 C 25.369937 38.010391 27.029766 38.513203 28.009766 38.783203 C 28.953766 39.043203 30.026906 38.817359 30.628906 38.443359 C 31.266906 38.046359 38.634109 33.117547 39.162109 32.685547 C 39.690109 32.253547 40.111688 32.808234 39.679688 33.240234 C 39.247687 33.672234 34.192391 38.579766 33.525391 39.259766 C 32.715391 40.084766 33.289031 40.93825 33.832031 41.28125 C 34.452031 41.67225 38.913938 44.664531 39.585938 45.144531 C 40.257938 45.625531 40.9375 45.841797 41.5625 45.841797 C 42.1875 45.841797 42.515172 45.020406 42.826172 44.066406 C 43.189172 42.950406 44.895469 31.821906 45.105469 29.628906 C 45.168469 28.964906 44.959828 28.521219 44.548828 28.324219 C 44.424578 28.264469 44.285062 28.228484 44.132812 28.210938 z"
            ></path>
          </svg>
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/lavrushkinskaya?igsh=MXIwbG5obmwxMXg4Zw=="
        >
          <svg
            class="t-sociallinks__svg"
            role="presentation"
            width="30px"
            height="30px"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM25 39.3918C25 31.4558 31.4566 25 39.3918 25H60.6082C68.5442 25 75 31.4566 75 39.3918V60.8028C75 68.738 68.5442 75.1946 60.6082 75.1946H39.3918C31.4558 75.1946 25 68.738 25 60.8028V39.3918ZM36.9883 50.0054C36.9883 42.8847 42.8438 37.0922 50.0397 37.0922C57.2356 37.0922 63.0911 42.8847 63.0911 50.0054C63.0911 57.1252 57.2356 62.9177 50.0397 62.9177C42.843 62.9177 36.9883 57.1252 36.9883 50.0054ZM41.7422 50.0054C41.7422 54.5033 45.4641 58.1638 50.0397 58.1638C54.6153 58.1638 58.3372 54.5041 58.3372 50.0054C58.3372 45.5066 54.6145 41.8469 50.0397 41.8469C45.4641 41.8469 41.7422 45.5066 41.7422 50.0054ZM63.3248 39.6355C65.0208 39.6355 66.3956 38.2606 66.3956 36.5646C66.3956 34.8687 65.0208 33.4938 63.3248 33.4938C61.6288 33.4938 60.2539 34.8687 60.2539 36.5646C60.2539 38.2606 61.6288 39.6355 63.3248 39.6355Z"
              fill="#ffffff"
            ></path>
          </svg>
        </a>
        <a target="_blank" href="https://www.behance.net/lavruskinskaya">
          <svg
            class="t-sociallinks__svg"
            role="presentation"
            width="30px"
            height="30px"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M50 100c27.6142 0 50-22.3858 50-50S77.6142 0 50 0 0 22.3858 0 50s22.3858 50 50 50Zm-1.4722-51.0072c1.0869-.5533 1.9078-1.167 2.4634-1.8412.9991-1.2023 1.4951-2.7852 1.4951-4.7637 0-1.9173-.4897-3.5669-1.4794-4.9411-1.6488-2.2414-4.4411-3.3824-8.38-3.4468H27v32.3572h14.5721c1.6395 0 3.162-.1468 4.5683-.4371 1.4017-.2904 2.6212-.8327 3.6517-1.6246.915-.6843 1.6763-1.5343 2.2892-2.5412.9638-1.5217 1.4378-3.2435 1.4378-5.1631 0-1.8561-.4207-3.4374-1.2612-4.7394-.835-1.3067-2.0836-2.2555-3.7301-2.859Zm-14.066-9.3729h6.0226c1.5476 0 2.8229.1719 3.8196.5062 1.1599.4803 1.7383 1.4794 1.7383 2.9846 0 1.3624-.4363 2.3112-1.3122 2.8449-.8766.5344-2.0138.8021-3.4209.8021h-6.8474v-7.1378Zm10.1153 20.5507c-.7848.3838-1.8717.5674-3.2765.5674h-6.838v-8.6233h6.9455c1.3867.0133 2.469.1938 3.2357.5486 1.371.6239 2.0577 1.7854 2.0577 3.4664 0 1.9926-.7094 3.3354-2.1244 4.0409Zm29.3836-24.827v3.2365H60.9299v-3.2365h13.0308Zm2.9849 10.7282c.9504 1.3797 1.5625 2.976 1.8474 4.7936.1578 1.0587.2284 2.5945.1994 4.602H62.1169c.0934 2.3293.897 3.9585 2.4227 4.8901.9197.5847 2.0349.8703 3.3408.8703 1.3766 0 2.4988-.3484 3.3629-1.0634.4709-.379.886-.9143 1.2447-1.59h6.1857c-.1616 1.3758-.9064 2.7712-2.2453 4.1893-2.0734 2.2539-4.9819 3.384-8.7167 3.384-3.085 0-5.8044-.9527-8.165-2.8519-2.3521-1.9063-3.534-4.9968-3.534-9.285 0-4.0205 1.0611-7.0985 3.1894-9.2402 2.1363-2.1464 4.8933-3.2145 8.2922-3.2145 2.0146 0 3.8298.3602 5.4504 1.0838 1.6151.7244 2.9493 1.8647 4.0009 3.4319Zm-13.0669 1.8098c-.8609.8789-1.3961 2.075-1.6159 3.5881h10.4426c-.1138-1.6104-.6514-2.83-1.6199-3.6627-.9606-.8358-2.1597-1.2549-3.5912-1.2549-1.5578 0-2.7609.4481-3.6156 1.3295Z"
              fill="#ffffff"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  );
};
